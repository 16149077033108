<!-- details start  -->
<section class="prod-details-page white-bg my-3 my-md-5">
    <div class="container-xxl">
        <app-page-loader *ngIf="loading"></app-page-loader>
        <ng-container *ngIf="!loading">
            <div class="row px-2 px-md-0">
                <div class="col-lg-6 col-md-8 col-12 prod-zoom-slider">
                    <div class="show position-relative">
                        <div class="share-list-show position-absolute">
                            <div class="fab btn-group show-on-hover dropup btn-io-results">
                                <div data-toggle="tooltip" data-placement="left" title="Share">
                                    <button type="button" class="btn-io">
                                        <i class="fa fa-share-alt"></i>
                                    </button>
                                </div>
                                <ul class="dropdown-menu dropdown-menu-right" role="menu">
                                    <li><a href="https://www.facebook.com" target="_blank" class="facebook"><i class="fa text-light fa-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com" target="_blank" class="instagram"><i class="fa text-light fa-instagram"></i></a></li>
                                    <li><a href="https://twitter.com" target="_blank" class="twitter"><i class="fa text-light fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com" target="_blank" class="linkedin"><i class="fa text-light fa-linkedin"></i></a></li>
                                    <li><a (click)="copyLink()" class="clipboard"><i class="fa text-light fa-clipboard"></i></a></li>          
                                </ul>
                            </div>
                        </div>
                        <!-- IF IMAGE -->
                        <ng-container *ngIf="primaryPicture.split('.').pop() !== 'mp4'">
                            <img [src]="primaryPicture" loading="lazy" [alt]="productDetail?.name" id="show-img">
                        </ng-container>
                        <ng-container *ngIf="primaryPicture.split('.').pop() == 'mp4'">
                            <video [src]="primaryPicture" class="img-fluid w-100 h-100" controls style="object-fit: cover;" id="show-img"></video>
                        </ng-container>
                        
                    </div>
                    <div class="small-img">
                        <div class="small-container">
                            <div id="small-img-roll">
                                <img (click)="primaryPicture = (getPorudctImagePath+productDetail.displayImage)" [src]="getPorudctImagePath+productDetail.displayImage" loading="lazy" role="presentation" class="show-small-img">
                                <ng-container *ngFor="let image of productDetail.gallery">
                                    <ng-container *ngIf="image.split('.').pop() !== 'mp4'">
                                        <img (click)="primaryPicture = (getPorudctImagePath+image)" [src]="getPorudctImagePath+image" loading="lazy" role="presentation" class="show-small-img">
                                    </ng-container>
                                    <ng-container *ngIf="image.split('.').pop() == 'mp4'">
                                        <img (click)="primaryPicture = (getPorudctImagePath+image)" src="assets/images/play-button-icon.webp" loading="lazy" role="presentation" class="show-small-img">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 col-12 mt-lg-0 mt-4">
                    <div class="stock-status" [ngClass]="productDetail?.inStock ? 'in' : 'out'">{{ productDetail?.inStock ? 'IN STOCK' : 'OUT OF STOCK'}}</div>
                    <div class="prod-title">
                        {{productDetail?.name}}
                    </div>
                    <div class="prod-rating-sec" *ngIf="productDetail.totalRating">
                        <app-rating [rating]="productDetail.avgRating" [totalRating]="productDetail.totalRating"></app-rating>
                        <!-- <span class="font14">{{productDetail.avgRating}}</span> -->
                        <!-- <span class="font14 fontw-400">({{productDetail.totalRating}} ratings)</span> -->
                    </div>
                    <div class="prod-pricing-sec">
                        <span class="prod-rating-del me-1" *ngIf="productDetail.discount">Rs {{productDetail.unitPrice}}</span>
                        <span class="prod-price">Rs {{productDetail.discountedPrice}}</span>
                        <span class="text-danger mb-0 ms-2" *ngIf="productDetail.discount">{{productDetail.discount}}</span>
                    </div>
                    <ng-container *ngFor="let varientType of productDetail.varients">
                        <div class="page-nums mb-4">
                            <div class="details-title">{{varientType.type}} Options</div>
                            <ng-container *ngIf="varientType.type == 'Color'; else others">
                                <div class="mt-2">
                                    <ul class="d-flex p-0">
                                        <li class="pointer color-wrapper ms-2" [ngClass]="{'active': varient.id == productId}" (click)="gotoProduct(varient.id)" *ngFor="let varient of varientType.data">
                                            <div class="color" [ngStyle]="{'background-color': varient.name}"></div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-template #others>
                                <div class="page-num-list mt-2">
                                    <ul class="d-flex p-0">
                                        <li class="pointer other-wrapper ms-2" [ngClass]="{'active': varient.id == productId}" (click)="gotoProduct(varient.id)" *ngFor="let varient of varientType.data">{{varient.name}}</li>
                                    </ul>
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                    <!-- <div class="page-nums" *ngIf="productDetail.varients.Page">
                        <div class="details-title">Page number</div>
                        <div class="page-num-list">
                            <ul>
                                <li *ngFor="let page of productDetail.varients.Page">{{page.name}}</li>
                                <li>120</li>
                                <li class="active">200</li>
                                <li>250</li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="delivery-options">
                        <div class="delivery-options-list">
                            <ul class="d-flex">
                                <li>
                                    <img src="assets/images/lowest-price.svg" alt="lowest-price">
                                    <span>Lowest price</span>
                                </li>
                                <li>
                                    <img src="assets/images/cash-on-delivery.svg" alt="cash-on-delivery">
                                    <span>Cash on delivery</span>
                                </li>
                                <li>
                                    <img src="assets/images/return-icon.svg" alt="return-icon">
                                    <span>Super Fast Delivery</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="prod-info" *ngIf="productDetail.attributes.length">
                        <div class="row">
                            <ng-container *ngFor="let attribute of productDetail.attributes">
                                <div class="col-6 mb-2">
                                    <div class="blue-txt">{{attribute.name}}</div>
                                    <p class="font14 txt-black">{{attribute.description}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="detail-add-buy-btn">
                        <button [disabled]="!productDetail.inStock" *ngIf="!getProduct" class="form-control dt-add-btn" [ngClass]="{'solid-btn': productDetail.inStock, 'btn btn-danger': !productDetail.inStock}" (click)="addToCart()">
                            <div *ngIf="cartLoading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <span class="solid_blob-btn__inner">
                                <span class="solid_blob-btn__blobs">
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                </span>
                            </span>
                            <ng-container *ngIf="!cartLoading">
                                <img src="assets/images/add-to-cart-bucket.svg" alt="add-to-cart-bucket"> <span>Add to Cart</span>
                            </ng-container>
                        </button>
                        
                        <div *ngIf="getProduct" class="btn-group cart-product-controls me-4" role="group">
                            <button type="button" class="btn px-3" (click)="descreaseQuanity()">-</button>
                            <div class="btn">{{getProduct.quantity}}</div>
                            <button type="button" class="btn px-3" (click)="increaseQuanity();">+</button>
                        </div>

                        <button [disabled]="!productDetail.inStock" class="form-control dt-buy-now-btn" [ngClass]="{'solid-btn': productDetail.inStock, 'btn btn-danger': !productDetail.inStock}" (click)="buyNow()">
                            <div *ngIf="buyLoading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <span class="solid_blob-btn__inner">
                                <span class="solid_blob-btn__blobs">
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                    <span class="solid_blob-btn__blob"></span>
                                </span>
                            </span>
                            <ng-container *ngIf="!buyLoading">
                                <img src="assets/images/money-hand.svg" alt="money-hand"> <span>Buy Now</span>
                            </ng-container>
                        </button>
                    </div>
                    <div class="detail-bluebg-content">
                        <div class="font14 txt-black" [innerHTML]="productDetail.longDescription">
                        </div>
                    </div>
                    <div class="detail-bluebg-content">
                        <div class="font16bold blue-txt"> <img src="assets/images/refund-icon.svg" alt="refund-icon"> Refund
                            & Cancellation policy</div>
                        <div class="font14 txt-black mt-2">
                            We have a "no questions asked return and refund policy" if product is unused.
                            Orders cannot be cancelled once packed for delivery. You can cancel the order at the time of
                            delivery.
                
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>

        <section class="similar-product py-5" *ngIf="!loading">
            <h2 class="txt-black text-center title">You may also like</h2>
            <app-product-carousel [products]="productDetail.otherProducts"></app-product-carousel>
        </section>
    </div>  
</section>
<!-- details ends  -->